

loadProductInfo = function(modal,id) {
	axios.get(`/api/v1/productinfo/${id}`, {
	})
		.then(({ data }) => {
			Object.keys(data).map(key => {
			  $('#product-info [data-col='+key+']').html(data[key]);
			  $('#product-info textarea[data-col='+key+']').attr('data-model_id',data['id']);
			  $('#product-info textarea[data-col='+key+']').val(data[key]);
			});
			$('div#reportUpload').html('');
			Object.keys(data.files).map(fileKey => {
				$('div#reportUpload').append(data.files[fileKey].icon);
			});
			modal.show();
	    })
	    .catch(function (error) {
	        UIkit.notification({
	        	message: 'Det skjedde en feil. Kunne ikke laste inn produktinfo',
	        	pos: 'bottom-right',
	        	status: 'danger'
	        })
	    });
}

loadModifyProductInfo = function(modal,id) {
	axios.get(`/api/v1/productinfo/${id}`, {
	})
		.then(({ data }) => {
			Object.keys(data).map(key => {
			  $('#product-info [data-col='+key+']').html(data[key]);
			  $('#product-info textarea[data-col='+key+']').attr('data-model_id',data['id']);
			  $('#product-info textarea[data-col='+key+']').val(data[key]);
			});
			$('div#reportUpload').html('');
			Object.keys(data.files).map(fileKey => {
				$('div#reportUpload').append(data.files[fileKey].modify);
			});
			modal.show();
	    })
	    .catch(function (error) {
	        UIkit.notification({
	        	message: 'Det skjedde en feil. Kunne ikke laste inn produktinfo',
	        	pos: 'bottom-right',
	        	status: 'danger'
	        })
	    });
}


jQuery(document).on('click', '.deleteThisImage', function(e){
	e.preventDefault();
	var id = $(this).data('id');
	var model = 'App\\File';
	// deleteModelById(model,id)
	axios.post('/api/v1/modelAndId', {
		_method : 'DELETE',
		model : model,
		id : id,
	})
	.then(({ data }) => {
		$('#file_'+id).remove();
    })
    .catch(function (error) {
        UIkit.notification({
                message:"<i class='fas fa-lg fa-fw fa-exclamation-triangle'></i> Kunne ikke slette fil.",
                pos:"bottom-right",
                status:'danger',
                timeout:2000
            });
    });
});
toggleWidgetMenu = function(type,id) {
	event.preventDefault();
	$('#widget_'+type+'_'+id).toggleClass('showWidgetPane');
}
closeWidgetMenu = function(type,id) {
	event.preventDefault();
	$('#widget_'+type+'_'+id).removeClass('showWidgetPane');
}

selectMany = function(widget,ref,id) {
	if($('.w_'+widget+'_'+ref+'_'+id).prop('checked')) {
		$('.w_'+widget+'_'+ref+'_'+id).prop('checked',false);
	} else {
		$('.w_'+widget+'_'+ref+'_'+id).prop('checked',true)
	}
}

saveOptionsToWidget = function(el,options) {
	saveOptionsToDb(el.data('widget'),'filter',options);
	$(el).attr('data-options',JSON.stringify(options));
}
saveSettingsToWidget = function(el,settings) {
	saveOptionsToDb(el.data('widget'),'settings',settings);
	$(el).attr('data-chart',JSON.stringify(settings));
}


saveOptionsToDb = function(id,col,params)
{
	var data = {
		[col]: params
	}
    axios.post('api/v1/widget/'+id, {
        data : data
    }).then(({ data }) => {
        // updateChart(chart,data,settings);
    })
    .catch(function (error) {
        console.log(error);
    });
}



/**
 * 	Finn input felt som er i div #widget_{widget}
 *  og har data-filter
 */

addFilterToOptionsArray = function(widget_id,chartEl) {
	var el = $('#widget_' + widget_id);
	let options = JSON.parse(el.attr('data-options'));
	let settings = JSON.parse(el.attr('data-chart'));
	let chart = $(el).data('chart');

	$('#widget_' + widget_id + ' .filterGroup').each(function(k,v){

		// Definerer filtergruppe - En filtergruppe for hver array key
		// nullstiller også eksisterende, slik at man kan legge inn nye verdier
		var group_el = $(v);
		var type = group_el.attr('filter-type');
		options[type] = [];

		// Looper igjennom hver filtergruppe for å hente ut verdiene som er registrert
		$(v).find('input[type=checkbox]').each(function(ik,iv){
			var new_el = $(iv);
			if(new_el.is(':checked')){
				options[type].push(new_el.val());
			}
		})
		$(v).find('select').each(function(ik,iv){
			var new_el = $(iv);
			options[type] = new_el.val();
		})
	});

	$('#widget_' + widget_id + ' .settingsGroup').each(function(k,v){

		// Definerer filtergruppe - En filtergruppe for hver array key
		// nullstiller også eksisterende, slik at man kan legge inn nye verdier
		var group_el = $(v);
		var type = group_el.attr('filter-type');
		settings[type] = [];

		// Looper igjennom hver filtergruppe for å hente ut verdiene som er registrert
		$(v).find('input[type=radio]').each(function(ik,iv){
			var new_el = $(iv);
			if(new_el.is(':checked')){
				settings[type] = new_el.val();
			}
		})
		$(v).find('select').each(function(ik,iv){
			var new_el = $(iv);
			settings[type] = new_el.val();
		})
	});

	closeWidgetMenu('filter',widget_id);
	closeWidgetMenu('settings',widget_id);
	var graph = $(widget_id+'_chart');



	saveOptionsToWidget(el,options);
	saveSettingsToWidget(el,settings);

    getChartData(chartEl,options,settings);
}

newGetChartData = function(chart,params,settings)
        {
            axios.get('api/v1/chart/kjede', {
                params : params
            }).then(({ data }) => {
                updateChart(chart,data,settings);
            })
            .catch(function (error) {
                console.log(error);
            });
        }
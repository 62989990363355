


$(document).ready(function(){
	// ping();
	window.setInterval(function(){
		// ping();
	}, 60000);
});

ping = function() {
	axios.get('/api/v1/ping', {})
	.then(({ data }) => {
		$('.currentSyncStatus').attr('data-count',data.currentUploadStatus);
    })
    .catch(function (error) {
        console.log(error);
    });
}


resetPW = function() {
    event.preventDefault();
    document.getElementById('resetpassword').submit();
}

$(document).on('click','.autoUpdateAdd',function(e) {
	$(this).select();
});

$(document).on('click','.selectOnClick',function(e) {
	$(this).select();
});

$(document).on('keyup','.autoUpdateAdd',function(e) {

	var code = e.keyCode || e.which;
    if (code == '9') {
    	$(this).select();
    }
});

$(document).on('keyup','.selectOnClick',function(e) {

	var code = e.keyCode || e.which;
    if (code == '9') {
    	$(this).select();
    }
});


$(document).on('click','.removeById',function(e){
	e.preventDefault();
	id = $(this).data('id');
	model = $(this).data('model');
	name = $(this).data('name');

	if(confirm('Er du sikker på at du vil slette ' + name + ' som mottaker?')) {
		deleteModelById(model,id);
	}
});


$(document).on('click','.removeWidgetById',function(e){
	e.preventDefault();
	id = $(this).data('id');
	model = $(this).data('model');
	if(confirm('Er du sikker på at du vil slette dette panelet?')) {
		deleteModelById(model,id);
	}
});

$(document).on('click', '#reference_save', function(e) {

	// oppdatere referansefelt i references table på aktuell leverandør på denne dato 
	let formdata = new FormData(document.getElementById("reference_form")); 

	axios({
		method: "post",
		url: "/reference",
		data: formdata,
	  })
		.then(function (response) {
		  //handle success
		  let data = response.data;
		  console.log(data.customer_id);
		})
		.catch(function (response) {
		  //handle error
		  console.log(response);
		});

});

deleteModelById = function(model,id) {
	axios.post(`/api/v1/modelAndId`, {
		_method: 'delete',
		id : id,
		model : model
	})
	.then(({ data }) => {
		// $('#'+model+'_'+id).hide();
		$('#removable_'+id).remove();
    })
    .catch(function (error) {
        UIkit.notification({
        	message: 'Det skjedde en feil! Vennligst last inn siden på nytt og prøv igjen',
        	pos: 'bottom-right',
        	status: 'danger'
        })
    });
}

addRecieverByModelId = function(el) {
	model = $(el).data('model');
	id = $(el).data('id');
	name = $('#create_reciever_name').val();
	send_forecast_on = $('#create_reciever_forecast').val();
	email = $('#create_reciever_email').val();
	axios.post(`/api/v1/reciever`, {
		id : id,
		model : model,
		name : name,
		email : email,
	})
	.then(({ data }) => {
		$('#emailRecievers').append(data.renderli);
		$('#addReciever input').val('');
		UIkit.modal("#addReciever").hide();
    })
    .catch(function (error) {
        UIkit.notification({
        	message: 'Det skjedde en feil! Vennligst prøv igjen',
        	pos: 'bottom-right',
        	status: 'danger'
        })
    });
}


function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};


orderLineSync = function(element, id) {

	//alert("OrderLineSync");

	$('#submitEditingNumbers').addClass('uploading');
	axios.post(`/api/v1/orderline/sync`, {
		id : $(element).data('id'),
		delivery_date : $(element).data('delivery_date'),
		next : $(element).data('next'),
		product_id : $(element).data('product'),
		value : $(element).val()
	})
		.then(({ data }) => {
			$(element).data('prev',$(element).val());
			
			if(data.error) {
				infoBarError(element);
				return;
			} 

			hideInfoBar(element);
	    })
	    .catch(function (error) {
	        infoBarError(element);
	    });
}
modelSync = function(element, id) {
	if(element.type == 'checkbox') {
		if($(element).is(':checked')) {
			element.value = 1;
		} else {
			element.value = 0;
		}
	}



	axios.post(`/api/v1/model/sync`, {
		key : $(element).data('key'),
		model_id : $(element).data('model_id'),
		model_type : $(element).data('model_type'),
		value : $(element).val()
	})
		.then(({ data }) => {
			Object.keys(data).map(key => {
				if(key == 'render_body') {
					$('#removable_'+data.id).replaceWith(data[key]);
				}
			});
	    })
	    .catch(function (error) {
	        UIkit.notification({
	        	message: 'Det skjedde en feil. Endringene ble ikke lagret',
	        	pos: 'bottom-right',
	        	status: 'danger'
	        })
	    });
}


leadTimeSync = function(element, id) {
	axios.post(`/api/v1/leadtime/sync`, {
		factory_id : $(element).data('factory'),
		customer_id : $(element).data('customer'),
		day_of_week : $(element).data('day'),
		value : $(element).val()
	})
		.then(({ data }) => {
	    })
	    .catch(function (error) {
	        UIkit.notification({
	        	message: 'Det skjedde en feil. Endringene ble ikke lagret',
	        	pos: 'bottom-right',
	        	status: 'danger'
	        })
	    });
}

hideCustomerSync = function(element) {
	if($(element).is(':checked')) {
		var val = 1;
	} else {
		var val = 0;
	}
	axios.post(`/api/v1/hidecustomer/sync`, {
		factory_id : $(element).data('factory'),
		customer_id : $(element).data('customer'),
		value: val,
	})
	.then(({ data }) => {
    })
    .catch(function (error) {
        UIkit.notification({
        	message: 'Det skjedde en feil. Endringene ble ikke lagret',
        	pos: 'bottom-right',
        	status: 'danger'
        })
    });
}

dispatchDaySync = function(element, id) {
	if($(element).is(':checked')) {
		var val = 1;
	} else {
		var val = 0;
	}
	axios.post(`/api/v1/dispatchday/sync`, {
		product_id : $(element).data('product'),
		dayofweek : $(element).data('dayofweek'),
		weektype : $(element).data('week'),
		value : val,
	})
		.then(({ data }) => {
	    })
	    .catch(function (error) {
	        UIkit.notification({
	        	message: 'Det skjedde en feil. Endringene ble ikke lagret',
	        	pos: 'bottom-right',
	        	status: 'danger'
	        })
	    });
}



createOrderLine = function(elem) {
	axios.post(`/api/v1/orderline/create`, {
		customer: elem.getAttribute('data-customer'),
		product: elem.getAttribute('data-product'),
		date: elem.getAttribute('data-date'),
	})
		.then(({ data }) => {
			document.getElementById(elem.getAttribute('data-target')).innerHTML = data;
	    })
	    .catch(function (error) {
	        UIkit.notification({
	        	message: error.response.data.message,
	        	pos: 'bottom-right',
	        	status: 'danger'
	        })
	    });
}

toggleInfoBar = function(source) {
	if($(source).data('prev') == $(source).val()) {
		return;
	}
	console.log(source);
	$(source).addClass('editing');
	$(source).removeClass('errorSyncing');
    $('#updateStatusBox').removeClass('error');
	$('#submitEditingNumbers').attr('target',$(source).attr('id'));
	// $('#currently_editing').html($(source).val());
	// $('#status').html($(source).attr('prev'));
	$('#updateStatusBox').addClass('editing');
}

hideInfoBar = function(source)
{
	$(source).removeClass('editing');
	$(source).removeClass('errorSyncing');
	$('#updateStatusBox').removeClass('editing');
	$('#submitEditingNumbers').removeClass('uploading');
	// $('#updateStatusBox').removeClass('editing');

}

infoBarError = function(source) {
	$('#submitEditingNumbers').removeClass('uploading');
	$('#updateStatusBox').addClass('error');
	$(source).removeClass('editing');
	$(source).addClass('errorSyncing');
}

norskDato = function(dato){

	dato = new Date(dato);

	d = dato.getDate() > 9 ? dato.getDate() : ('0'+ dato.getDate());
	m = (dato.getMonth() > 8) ? (dato.getMonth()+1) :('0'+(dato.getMonth()+1)); 
	y = dato.getFullYear();
	
	let response = d + '.' + m + '.' +y;
	return response;

}



	updateReferenceForm = function(product_id, dispatch_date, antall){
		
		if(antall > 0) {

			// hvis referanse input wrapper
			$("#reference_wrapper").show();

			console.log('updateReference started');
			let dato = norskDato(dispatch_date);
			$('#reference_dispatch_date_span').html(dato);
			$('#reference_dispatch_date').val(dispatch_date);
	
			// hent riktig fabrikk fra dette produktet
			axios.get('fabrikk/'+ product_id, {})
				.then(({ data }) => {
					// console.log(data);
					$('#reference_factory_name').html(data.name);
					$('#reference_factory_id').val(data.id);
	
					// oppdatere referanse feltet hvis det finnes
					getReference(data.id, dispatch_date);
			})
			.catch(function (error) {
				console.log(error);
			});
	
		} else {

			$("#reference_wrapper").hide();
		}
	
		

	}


	getReference = function(factory_id, dispatch_date) {	
		console.log('getReference started');
		axios.get('/reference', {
			params: {
				factory_id: factory_id,
				dispatch_date: dispatch_date,
			}
		})
		.then(({ data }) => {
			console.log(data);

			$('#reference_input').val(data.reference);

		})
		.catch(function (error) {
			console.log(error);
		});

	}






$(document).ready(function(){

	$(document).on('change','.syncDispatchDay',function(e) {
		dispatchDaySync(this, $(this).data('id'));
	})

	// $(document).on('keyup','.autoUpdateAdd',function(e) {

	// 	$(this).attr('data-changed','true');
	// });

	var autoUpdateAdd = debounce(function() {
		if($(this).data('prev') != $(this).val()) {
			orderLineSync(this, $(this).data('id'));
			$(this).attr('data-changed','false');
		}
	}, 5);

	//$(document).on('keyup','.autoUpdateAdd', autoUpdateAdd);



	$(document).on('click','#submitEditingNumbers',function(e) {
		console.log("submitEditingNumbers");

		e.preventDefault();
		el = '#'+ $(this).data('target');
	

		if($(el).data('prev') != $(el).val()) {
			console.log("Yes");
			orderLineSync(el, $(el).data('id'));
			$(el).attr('data-changed','false');
		}
	})


	$(document).on('change','.autoUpdateAdd',function(e) {
		
		//console.log("Changed");
		if($(this).data('prev') != $(this).val()) {
			// show reference modal
			
			// reference modal send to orderLineSync
			orderLineSync(this, $(this).data('id'));
			$(this).attr('data-changed','false');
		} 
	}) 



	$(document).on('focus , keyup','.autoUpdateAdd',function(e) {
		
		console.log(this);
		let product_id = $(this).attr('data-product');
		let dispatch_date = $(this).attr('data-delivery_date');
	
		let antall = $(this).val();
		
		updateReferenceForm(product_id, dispatch_date, antall);
		
		
		
		toggleInfoBar(this);
	})



	$(document).on('keyup','.autoUpdateModel',function(e) {
		$(this).attr('data-changed','true');
	});

	$(document).on('keyup','.autoUpdateModel',function(e) {
		if($(this).data('changed') == true) {
			modelSync(this, $(this).data('id'));
			$(this).attr('data-changed','false');
		}
	})
	$(document).on('keyup','.autoUpdateModelChange',function(e) {
		$(this).attr('data-changed','true');
	});

	$(document).on('change','.autoUpdateModelChange',function(e) {
		if($(this).data('changed') == true) {
			modelSync(this, $(this).data('id'));
			$(this).attr('data-changed','false');
		}
	})
	$(document).on('change','.autoUpdateModelSelect',function(e) {
		modelSync(this, $(this).data('id'));
	})

	$(document).on('keyup','.leadTimeSync',function(e) {
		$(this).attr('data-changed','true');
	});

	$(document).on('keyup','.leadTimeSync',function(e) {
		if($(this).data('changed') == true) {
			leadTimeSync(this, $(this).data('id'));
			$(this).attr('data-changed','false');
		}
	})




});
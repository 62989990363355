
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('./chartAjax');
require('./dashboardWidget');
require('./table');
require('./sync');
require('./ping.js');
require('./filter.js');
require('./panel.js');
require('./productinfo.js');

import UIkit from 'uikit';
import 'babel-polyfill'
window.UIkit = UIkit;

import Chart from 'chart.js';
import Dropzone from 'dropzone';
window.Dropzone = Dropzone;

import 'jquery-ui/ui/widgets/autocomplete.js';
/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.$ = window.jQuery = require('jquery');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

// window.Vue = require('vue');

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

// // Vue.component('Orderline', require('./components/OrderLine.vue').default);
// // Vue.component('Orderlines', require('./components/OrderLines.vue').default);
// Vue.component('product-line', require('./components/ProductLine.vue').default);
// Vue.component('product-orderline', require('./components/ProductOrderLine.vue').default);

// Vue.prototype.$days = {}

// window.onload = function () {
// 	const app = new Vue({
// 	    el: '#loadedList'
// 	});
// }


$(document).on('click', '.toggleNav', function(e) {
	e.preventDefault();
	$('#toggleNav').toggleClass('visible');
})
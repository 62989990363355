


$(document).on('click','#filterByFactory .toggle',function(e){
	$('#filterByFactory .filterOptions').toggle();
})

$(document).on('change','.validateNum',function(){
	validateNumber($(this));
});

allEqual = function(arr) {
	if(arr.every( v => v === arr[0] ) && arr.length > 1) {
		return true;
	}
	return false;
}

validateNumber = function(el) {
	avg = $(el).data('avg');
	val = $(el).val();
	arr = Array.from(val);
	avg = avg >= 1 ? avg : 1;
	max = avg * 9.5;

	times = val / avg;
	times = Math.ceil(times);

	if(val > max ){
		alert(`Du har fylt inn ${val} som er ${times} ganger større enn snittet for hva du har lagt inn samme ukedag foregående uker.\nOm dette er en feil, vennligst endre tilbake.`);
		return true;
	}

	if(allEqual(arr)){
		alert(`Du har fylt inn to like tall. Vi vil bare sjekke at du er oppmerksom på dette. \nOm dette er en feil, vennligst endre tilbake.`);
	}
}
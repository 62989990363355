
$(document).on('click','.toggleMany',function(e) {
	id = $(this).data('id');

	if($('.toggle_'+id).is(":checked")) {
		$('.toggle_'+id).click();
	} else {
		$('.toggle_'+id).click();
	}
});

$(document).on('keyup','.intval',function(e) {
	val = $(this).val();
    $(this).val(val.replace(/[^0-9]/g, ""))
});

$(document).on('keyup','.weeknum',function(e) {
	val = $(this).val();
	val = val.replace(/[^0-9]/g, "");
    val = parseInt(val) < 0 ? 0 : val;
    val = parseInt(val) > 53 ? 53 : val;
    $(this).val(val);
});



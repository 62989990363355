








addPanel = function() {
    axios.post(`/api/v1/widget`, {
    })
    .then(({ data }) => {
        $('#sortable').append(data.render_body);
    })
    .catch(function (error) {
        UIkit.notification({
            message: 'Det skjedde en feil! Vennligst last inn siden på nytt og prøv igjen',
            pos: 'bottom-right',
            status: 'danger'
        })
    });
}

updatePanel = function(element) {
	axios.post(`/api/v1/model/sync`, {
        key : $(element).data('key'),
        model_id : $(element).data('model_id'),
        model_type : $(element).data('model_type'),
        value : $(element).val()
    })
    .then(({ data }) => {
        var target = $(element).data('target');
        $('#'+target).replaceWith(data.render_body)
        console.log(data);
    })
    .catch(function (error) {
        UIkit.notification({
            message: 'Det skjedde en feil. Endringene ble ikke lagret',
            pos: 'bottom-right',
            status: 'danger'
        })
    });
}